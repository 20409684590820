.react-add-to-calendar{
  width: 40px;
}
.react-add-to-calendar__wrapper{
  top: 10px;
    position: relative;
    font-size: 17px;
    text-align: center;
}

.react-add-to-calendar__wrapper a{
  color: rgba(0, 0, 0, 0.54);
}

.other-links{
  max-height: 40px !important;
}

.react-add-to-calendar__dropdown{
  width: 150px !important;
  position: relative;
    top: 20px;
    left: 1px;
    width: 93%;
    padding: 5px 0 5px 8px;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.15);
    border: 1px solid #a8a8a8;
    background-color: #fff;
    text-align: left;
}
.react-add-to-calendar__dropdown ul{
  list-style: none;
  margin: 0;
  padding-left: 0px !important
}
.react-add-to-calendar__dropdown li{
  margin: 4px 0px;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

.react-add-to-calendar__dropdown li .fa{
  margin-right: 5px;
}
